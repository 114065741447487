<template>
  <v-app>

    <div id="main" class="d-flex align-center justify-center">
        <v-card class="border" width="800" tile color="transparent" flat>
           <h3 class="text-center   font-weight-black white--text">
            Manage All Business Things In One Account
          </h3>
          <h3 class="text-center font-weight-lighter white--text px-6 px-md-12 ">
            Etousoft aims to help businesses grow through quality and relevant software
          </h3>
          <div class="text-center">
            <v-btn id="btn" @click="createAccount" large min-width="250" class="white--text mt-10" outlined rounded >Create Account</v-btn >
          </div>
        </v-card>
    </div>
    <!-- Products -->
    <div>
      <v-container class="mt-12">
        <h2 class="text-center">Featured Products</h2>
        <Products class="mt-12" />
      </v-container>
    </div>

    <!--End Products -->
    <hr class="mt-12" />
    <!-- Why Etousofy Products -->

    <div>
      <v-container class="mt-12" >
        <v-row justify="center" align="center">
          <v-col cols="12">
            <h2 class="text-center">Why Etousoft Products</h2>
          </v-col>
        </v-row>
        <v-row dense class="mt-8">
          <v-col cols="12" md="4">
            <div >
            <v-img
              width="250"
              height="200"
              class="mx-auto"
              src="@/assets/business.svg"
            ></v-img>
          </div>

          <h2
              class="title black--text font-weight-medium text-center mt-5 "
            >
              Business expertise
            </h2>
            <p class="black--text body-2 px-4 ">
              Etousoft provides business-related software to help entrepreneurs define management solutions that will drive competitive advantage and business growth.
            </p>
       
          </v-col>
          <v-col cols="12" md="4">
            <div class="">
            <v-img
              width="250"
              height="200"
              class="mx-auto"
              src="@/assets/mobile.svg"
            ></v-img>
          </div>
          <h2
              class="title black--text  font-weight-medium text-center mt-5 "
            >
              Access your app anytime, anywhere
            </h2>
            <p class="black--text body-2 px-4 ">
              With all data synced online, you can manage your team using desktop and mobile apps any time and from anywhere.
            </p>
         
          </v-col>
          <v-col cols="12" md="4">
            <div>
            <v-img
              width="250"
              height="200"
              class="mx-auto"
              src="@/assets/savings.svg"
            ></v-img>
          </div>
            <h2
              class="title black--text font-weight-medium text-center mt-5 "
            >
              Get paid
            </h2>
            <p class="black--text body-2 px-4">
              Earn discounts and commissions through our affiliate program by inviting customers to use our products.
            </p>
         
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>
<script>
import Products from "../components/Product/productspartial";


export default {
  components: {
    Products,

  },
  data: () => ({
    model: "",
  }),
  created() {
    if (this.$user.SuccessLogin()) {
      this.$router.push({ name: "Dashboard" });
    }
  },
  methods: {
    createAccount() {
      window.location.href = this.$config.register
    },
  }
};
</script>
<style scoped>
#main {
  background-image: linear-gradient(
      to bottom,
      rgba(2, 0, 36, 0.8),
      rgba(0, 146, 155, 0.3)
    ),
    url("../assets/helpcenter.jpg");
  height: 420px;
  background-position: center;
  background-size: cover;
  color: white;
  padding: 20px;
}
.btn {
  text-transform: none;
}
#targetAudeince path {
  fill: transparent;
}
 

</style>
